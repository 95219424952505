$black: #1d1d21;
$light-blue: #8b92c0;
$electric-blue: #4a57b5;
$electric-blue-hover: #364194;
$grey: #989baa;
$dark-grey: #63656e;
$dark-grey-blue: #3d3f48;
$deck-cover-white: #efefef;
$deck-cover-blue: #3e4154;
$flashcard-white: #cfd2e3;
$muted-green: #8bc0a3;
$muted-red: #c08b8b;
$light-red: #e16060;
$red: red;

$white: #ffff;

$blue: #25283d;
$blue-hover: #1e1f31;
$light-placeholder: #636b8b;
$dark-placeholder: #595c80;

$blue-gradient: linear-gradient(
  90deg,
  #4a57b5,
  #196eca,
  #0084da,
  #0099e4,
  #00ade9,
  #00c0ea,
  #00d3e8,
  #06e4e4
);
