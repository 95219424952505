@import '@/assets/colors';
@import '@/assets/styles';

$search-bar-offset: ($content-padding - $header-gap);

.c-header {
  z-index: $z-header;
  height: $header-height;
  width: 100%;
  box-sizing: border-box;
  background-color: $deck-cover-blue;
  border-bottom: 1px solid $deck-cover-blue;

  // all browsers but not supported below iOS/Safari 16.0 (09/11/2022)
  @supports (overflow-x: clip) {
    overflow-x: clip;
    overflow-y: visible;
  }

  &.fixed {
    position: fixed;
  }

  &.darker {
    background-color: $blue;
  }

  .c-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: $header-gap;

    .c-header-nav {
      display: flex;
      align-items: center;
      padding-left: 16px;
      width: 24px;
      flex: 1;

      .animated-icon {
        @include hover-pointer;
        display: flex;
        align-items: center;
      }

      @media screen and (min-width: $large-screen) {
        display: none;
      }
    }

    .c-header-title {
      @include flex-center;
      height: 100%;
      flex: 0 0 $sidebar-width;
      color: $white;
      font-size: 30px;

      @media screen and (max-width: $small-screen) {
        flex: 0;
        padding-left: ($header-gap);
      }

      a.c-header-anchor {
        color: unset;
        text-decoration: none;
      }

      label {
        @include hover-pointer;
        user-select: none;

        > span {
          font-weight: bold;
        }

        &:hover {
          @include standard-transition;
          color: $grey;
        }
      }
    }

    .c-search-bar-container {
      display: flex;
      align-items: center;
      flex: 8 1 auto;

      @media screen and (max-width: $large-screen) {
        display: none;
      }

      .c-search-bar-sizer {
        width: min(calc(100%), 500px);
      }
    }

    .c-account-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 0 0 min(20vw, 100px);
      padding-right: $header-gap * 2;

      @media screen and (max-width: $small-screen) {
        padding-right: $header-gap;
      }

      button {
        padding: 12px;
        border: none;

        &.sign-up-button {
          background-color: transparent;
        }

        &.sign-in-button {
          background-color: $electric-blue;
        }
      }
    }
  }
}
