@import '@/assets/colors';
@import '@/assets/styles';

svg.doc-icon {
  @include standard-transition;
  @include hover-pointer;

  &.dark path {
    stroke: $blue;
  }

  &.light path {
    stroke: $white;
  }
}
