@import '@/assets/colors';
@import '@/assets/styles';

.profile-picture-container {
  position: relative;
  width: fit-content;
  height: fit-content;

  .profile-picture {
    border-radius: 50%;
  }

  &.prof-pic-glow {
    &::after {
      @include white-infinite-pulse(3s);
      content: '';
      position: absolute;
      inset: 2px;
      border-radius: 50%;
      background: $blue-gradient;
      filter: blur(1em);
      z-index: -1;
    }
  }
}
