@import '@/assets/colors';
@import '@/assets/styles';

$button-strip-height: 35px;

.card-face {
  @include standard-transition;
  @include hover-pointer;
  position: relative;
  font-size: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex: 1;
  transition: 200ms;
  background-color: $flashcard-white;
  border-radius: 14px;

  &::placeholder {
    color: $light-placeholder;
  }

  &.active {
    @include hover-text;
    background-color: $flashcard-white;
    color: $blue;

    &::placeholder {
      color: $dark-placeholder;
    }
  }

  &.inactive {
    background-color: $black;
    border: 1px solid $light-blue;
    color: $light-blue;
  }

  .card-face-textarea {
    @include hover-pointer;
    background-color: transparent;
    color: inherit;
    width: 100%;
    overflow: visible;
    resize: none;
    text-align: center;
    font-size: 20px;
    outline: none;
    border: none;
    padding: 15px 25px;
  }
}
