@import '@/assets/colors';
@import '@/assets/styles';

svg.speaker-icon.white path {
  &:first-child {
    fill: $white;
    stroke: none;
  }
  stroke: $white;
}

svg.speaker-icon.red path {
  &:first-child {
    fill: $muted-red;
    stroke: none;
  }
  stroke: $muted-red;
}

svg.speaker-icon.green path {
  &:first-child {
    fill: $muted-green;
    stroke: none;
  }
  stroke: $muted-green;
}
