@import '@/assets/colors';
@import '@/assets/styles';

svg.reorder-icon {
  @include standard-transition;
  &.inactive {
    fill: $light-blue;
  }
  &.active {
    fill: $flashcard-white;
  }
}
