@import '@/assets/colors';
@import '@/assets/styles';

.sign-in-panel {
  .field-container {
    display: flex;
    flex-direction: column;
    align-self: normal;
    gap: 6px;

    &.centered {
      align-items: center;
      text-align: center;
    }

    .info-text {
      max-width: $tiny-screen;
      padding: 0px 4px;
      text-align: left;
      font-size: 13px;
      color: $grey;

      &.error {
        color: $light-red;
      }
    }
  }
}
