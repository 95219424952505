@import '@/assets/colors';
@import '@/assets/styles';

.c-author-tag {
  @include standard-transition;
  @include hover-pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  padding: 10px 20px;
  border: 1px solid $deck-cover-blue;
  background-color: transparent;
  color: $light-blue;
  border-radius: 20px;

  &:hover {
    border-color: $light-blue;
    color: $white;
  }

  .c-author-tag-profile-pic img {
    width: 50px;
    height: 50px;
  }
}
