@import '@/assets/colors';
@import '@/assets/styles';

.c-drop-down-options {
  @include floating-card;
  width: 100%;
  margin-top: 2px;
  cursor: default;

  button.c-drop-down-option {
    @include white-hover;
  }

  &:not(.ellipsis-overflow) {
    min-width: fit-content;
  }

  &.ellipsis-overflow {
    @include ellipsis-overflow;
  }

  .c-options-grid {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    width: 100%;
  }

  .c-drop-down-option {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-left: 1px solid $flashcard-white;
    border-right: 1px solid $flashcard-white;
    color: $blue;
    padding: 5px;
    border-radius: 0;
    text-align: left;

    &.ellipsis-overflow {
      @include ellipsis-overflow;
    }

    &:first-child {
      border-top-left-radius: $standard-radius;
      border-top-right-radius: $standard-radius;
      border-top: 1px solid $flashcard-white;
    }

    &:last-child {
      border-bottom-left-radius: $standard-radius;
      border-bottom-right-radius: $standard-radius;
      border-bottom: 1px solid $flashcard-white;
    }
  }
}
