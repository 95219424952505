@import '@/assets/styles';
@import '@/assets/colors';

.registration-component-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &::before,
  &::after {
    content: '';
    flex: 1 0 24px;
  }

  .registration-component {
    margin-left: auto;
    margin-right: auto;
    border: 2px solid $light-blue;
    width: fit-content;
    color: white;
    padding: 45px;
    text-align: center;
    border-radius: 45px;

    @media screen and (max-width: $small-screen) {
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      margin: none;
      border: none;
    }

    .registration-header {
      @include standard-transition;
      &:hover {
        color: $light-blue;
      }

      font-size: 36px;
      color: unset;
      text-decoration: none;
    }

    .registration-sub-header {
      font-size: 16px;
      margin-bottom: 50px;
    }

    // all registration buttons
    .registration-button {
      border-color: $white;
      color: $white;
      padding: 10px;

      &:not(.disabled) {
        @include hover($electric-blue);
        @include hover-pointer;
        background-color: transparent;
        display: inline-block;
      }
    }

    .registration-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-bottom: 40px;

      .registration-form-header {
        font-size: 12px;
      }

      .registration-button {
        .submit-form-toggle {
          @include flex-center;
          width: fit-content;
          height: 20px;

          .submit-form-loading {
            @include flex-center;
            label {
              padding-left: 8px;
            }
          }
        }
      }
    }

    .registration-button-group {
      @include flex-center;
      margin-top: 40px;

      .registration-button {
        margin: 8px;
      }

      .continue-as-guest-toggle {
        @include flex-center;
        width: fit-content;

        .continue-as-guest-loading {
          @include flex-center;
          label {
            padding-left: 8px;
          }
        }
      }
    }
  }
}
