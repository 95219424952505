@import '@/assets/colors';
@import '@/assets/styles';

.c-sidebar {
  width: $sidebar-width;
  box-sizing: border-box;
  padding: $header-height 20px 0px 20px;
  height: 100vh;
  position: fixed;
  background-color: $blue;
  color: $white;
  border-right: 1px solid $light-blue;

  &.nav-toggled {
    display: block;
    z-index: $z-sidebar;
    box-shadow: inset 0px ($header-height + 1) 0px 0px $light-blue;
  }

  .c-sidebar-divider {
    height: 1px;
    background-color: $light-blue;
    border: none;
    margin: 0px;
  }

  .c-sidebar-items {
    padding-left: 30px;
    list-style-type: none;
    font-size: 20px;

    .c-sidebar-link {
      color: inherit;
      text-decoration: none;

      // todo: change this to have a better style
      // custom style when link is active
      &.active {
        text-decoration: underline;
        text-decoration-color: $grey;
        text-underline-offset: 3px;
      }
    }

    .c-sidebar-item {
      margin-bottom: 20px;

      @include hover-pointer;
      &:hover {
        color: $grey;
      }
    }
  }
}
