@import '@/assets/colors';
@import '@/assets/styles';

.c-file-input {
  min-width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  border: 1px solid $grey;
  padding: 10px 15px;
  border-radius: 5px;
  input {
    display: none;
  }
  .c-file-input-button {
    padding: 0;
    label {
      display: inline-block;
      padding: 8px 20px;
      cursor: pointer;
    }
  }
  .c-selected-file {
    color: $blue;
    @include flex-center;
    overflow: auto;
    .remove-file-button {
      margin-top: 3px;
      margin-left: 8px;

      .remove-file-icon {
        width: 25px;
        height: 25px;
      }
    }
    overflow-wrap: anywhere;
  }
}
