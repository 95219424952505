@import '@/assets/colors';
@import '@/assets/styles';

@mixin deck-description {
  margin: 10px 0px;
  color: $grey;
  font-size: 20px;
}

@mixin deck-divider {
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid $light-blue;
  margin: 30px 0px;
}
