@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;700&display=swap');
@import '@/assets/colors';
@import '@/assets/styles';

// textarea also supports a 'col' attribute: 'col=20' => fit 20 characters per row
// using this attribute would mean being able to remove `width: 100%` definitions in CSS

$label-font-size: 14px;
$label-legend-font-size: 12px;

.c-text-area-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  width: 100%;

  &:hover .c-copy-icon {
    @include hover-pointer;
    visibility: visible;
    opacity: 1;
  }
}

.c-copy-icon {
  position: absolute;
  top: 10px;
  right: 10px;

  transition: visibility 200ms ease-out, opacity 200ms ease-out;
  visibility: show;
  opacity: 0;

  &:active {
    fill-opacity: 0.5;
  }
}

.c-text-area-label {
  width: fit-content;
  min-height: $label-font-size + 2; // account for textarea border
  padding: 0px 2px;
  font-size: $label-font-size;
  transition: transform 100ms ease-in-out;

  &.as-legend {
    font-size: $label-legend-font-size;
    padding: 0px 4px;
    transform: translate(16px, $label-font-size - 2px);
  }

  .light & {
    background-color: $white;
    color: $grey;
  }

  .dark & {
    background-color: $black;
    color: $light-blue;
  }
}

.c-text-area {
  @include webkit-scrollbar;
  font-family: 'Comfortaa';
  border: 1px solid $white;
  border-radius: $standard-radius;
  box-sizing: border-box;
  outline: none;
  padding: 16px;
  width: 100%;

  .light & {
    background-color: $white;
    border-color: $grey;
    color: $blue;

    &:focus,
    &:focus-visible {
      outline: auto;
      outline-color: $grey;
    }
  }

  .dark & {
    border-color: $light-blue;
    color: $white;
    background-color: $black;
  }

  &.readonly {
    background-color: $flashcard-white;
    color: $blue;
  }
}

.c-text-area-footer {
  display: flex;
  padding: 0px 2px;
  gap: 4px;

  > label {
    padding-top: 1px;
    font-size: 10px;

    .light & {
      color: $grey;
    }

    .dark & {
      color: $light-blue;
    }
  }
}
