@import '@/assets/colors';
@import '@/assets/styles';

.deck-editor {
  .deck-editor-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .deck-editor-save-buttons {
      @include flex-center;
      gap: 16px;

      @media screen and (max-width: $tiny-screen) {
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
      }

      .discard-changes-button {
        @include standard-transition;
        border: 1px solid transparent;
        color: $light-blue;

        &:hover {
          color: $white;
        }
      }

      .save-changes-button {
        padding: 16px 12px;

        .save-changes-content-container {
          @include flex-center;
          width: 75px;
          height: 25px;

          .save-changes-loading {
            @include flex-center;
            label {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }

  .deck-editor-flashcard-set {
    margin-bottom: 50px;
  }

  .flashcard-set-placeholder {
    @include flex-center;
    margin: 100px 0px;
    color: $white;
  }

  .add-card-button {
    position: sticky;
    bottom: 20px;

    label {
      margin-left: 10px;
    }
  }
}
