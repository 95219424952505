@import '@/assets/colors';
@import '@/assets/styles';

.c-flip-tile {
  width: 325px;
  height: 200px;
  font-size: 20px;
  visibility: hidden;

  perspective: 800px;
  transition: 0.25s;

  .c-card {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.09, 0.6, 0.32, 1.28);
  }
  .c-front,
  .c-back {
    width: 100%;
    height: 100%;
    position: absolute;
    visibility: visible;
    backface-visibility: hidden;
  }

  .c-front {
    text-align: center;
  }

  .c-back {
    transform: rotateY(180deg);
  }
  .c-card.is-flipped {
    transform: rotateY(180deg);
  }
}

.tile-content {
  width: 100%;
  height: 100%;
  background-color: $deck-cover-white;
  border-radius: 15px;
  box-sizing: border-box;
}
