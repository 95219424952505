@import '@/assets/colors';

$arrow-height: 14px;

svg.arrow-icon {
  padding: 0px 10px;
  width: $arrow-height;
  height: $arrow-height;

  &.dark {
    fill: $blue;
  }

  &.light {
    fill: $white;
  }

  &.grey {
    fill: $dark-grey;
  }

  &.up {
    transform: rotate(180deg);
  }
}
