@import '@/assets/styles';
@import '@/assets/colors';

.radio-button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .button-group-radio-button {
    padding: 8px 20px;
  }

  &.light {
    .selected {
      border-color: transparent;
    }
  }

  &.dark {
    .unselected {
      background-color: transparent;
      color: $light-blue;
    }
  }
}
