@import '@/assets/styles';

.reorder-button-strip {
  height: 75%;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.active {
    color: $flashcard-white;
  }

  .center-icon {
    @include flex-center;

    > div {
      position: absolute;
    }

    .strip-reorder-icon {
      width: 15px;
    }
  }
}
