.c-kebab-menu {
  position: relative;

  .c-kebab-menu-button {
    padding: 10px;
  }

  .c-kebab-menu-options {
    width: fit-content;
    right: 20px;
  }
}
