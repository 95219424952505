@import '@/assets/colors';
@import '@/assets/styles';

@mixin icon-path-colors {
  &.white path {
    fill: $white;
  }

  &.green path {
    fill: $muted-green;
  }

  &.red path {
    fill: $muted-red;
  }

  &.light-blue path {
    fill: $light-blue;
  }
}

.kebab-icon {
  @include icon-path-colors;
}
