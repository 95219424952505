@import '@/assets/colors';
@import '@/assets/styles';

.drop-down {
  > label {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 4px;

    &.light {
      color: $white;
    }

    &.dark {
      color: $blue;
    }
  }

  // custom classes (pass in with prop)
  &.align-right {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

.drop-down-button {
  @include ellipsis-overflow;
  @include hover-pointer;
  width: 100%;
  padding: 5px 0px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// avoid nesting in .drop-down to make menu more easily targetable
.drop-down-menu {
  position: relative;
  display: inline-block;

  // consumer should set width manually if a fixed size is desired
  width: fit-content;
}
