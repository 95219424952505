@import '@/assets/styles';
@import '@/assets/colors';

.c-bubble-divider {
  @include flex-center;

  .c-bubble-button {
    @include flex-center;
    border-radius: 30px;
    background-color: $black;
    color: $light-blue;
    padding: 10px 35px;

    .c-bubble-divider-arrow {
      @include flex-center;
      height: 100%;
      padding-left: 10px;
      fill: $light-blue;
    }

    &.divider:hover {
      cursor: default;
      background-color: $black;
    }
  }

  hr {
    @include light-hr;
  }

  &.light {
    .c-bubble-button {
      color: $grey;
      border-color: $grey;
      background-color: $white;

      &.divider:hover {
        background-color: $white;
      }

      &.drop-down {
        @include white-hover;
      }

      .c-bubble-divider-arrow {
        fill: $grey;
      }
    }

    hr {
      border-color: $grey;
    }
  }
}
