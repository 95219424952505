@import '@/assets/styles';

.flashcard {
  @include flex-center;
  @include standard-transition;
  width: 100%;
  gap: 20px;
  margin: 10px 0px;
  align-items: stretch;
  height: fit-content;
  min-height: 150px;
  overflow: visible;
}
