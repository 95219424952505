@import '@/assets/styles';
@import '@/assets/colors';

.deck-tile {
  @include outlined-tile(350px, 175px);
  background-color: transparent;
  display: inline-flex;
  flex-direction: column;
  padding: 10px;

  &:hover,
  &:focus {
    .dt-text-light-blue {
      color: $white;
    }

    .dt-text-gray {
      color: $light-blue;
    }
  }

  .dt-text-light-blue {
    @include standard-transition;
    color: $light-blue;
  }

  .dt-text-gray {
    @include standard-transition;
    color: $grey;
  }

  .dt-title {
    color: $white;
    font-size: 20px;
    margin-bottom: 4px;
  }

  .dt-description {
    flex-grow: 1;
    overflow-y: auto;
    text-align: left;
    padding: 5px 0px;
    margin: 0px;
  }

  .dt-author {
    width: 100%;
    text-align: right;
  }
}
