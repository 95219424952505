@import '@/assets/colors';
@import '@/assets/styles';

.c-card-menu {
  position: absolute;
  top: 0;
  right: 0;

  .card-menu-language-dropdown {
    white-space: nowrap;
  }

  .card-menu-option {
    display: flex;
    align-items: center;

    &.disabled-card-menu-option {
      color: $grey;
    }
  }

  .card-menu-record-audio-icon {
    width: 20px;
    height: 20px;
  }

  .card-menu-icon {
    margin-right: 10px;
  }
}
