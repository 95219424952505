@import '@/assets/colors';
@import '@/assets/styles';

.white path {
  fill: $white;
}

.grey path {
  fill: $grey;
}
