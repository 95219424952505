@import '@/assets/colors';
@import '@/assets/styles';

.deck-meta {
  .study-button {
    padding: 20px 40px;
    border-radius: 10px;
  }

  .deck-meta-title {
    margin-top: 10px;
    input {
      font-size: 30px;
      border-radius: 10px;
      border-width: 1px;
      padding: 11px 8px;
    }
  }

  .title,
  .c-description-and-import-buttons {
    margin-top: 16px;
  }

  .c-description-and-import-buttons {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 16px;
    gap: 16px;

    .description-text-area {
      flex: 4 1 600px;

      textarea {
        padding: 12px 12px;
        height: 58px;
        border-radius: 10px;
      }
    }

    .popup-buttons {
      @include flex-center;
      flex: 1 1 200px;
      gap: 16px;
    }
  }

  .advanced-settings-divider {
    margin: 25px 0px;
    color: $white;

    .advanced-settings {
      > * {
        margin-bottom: 20px;
      }

      .deck-meta-access-level-label {
        margin-bottom: 8px;
      }

      .swap-all-button {
        @include flex-center;
        gap: 10px;
        padding: 10px;
      }
    }
  }
}
