@import '@/assets/colors';

.language-icon {
  &.dark {
    fill: $blue;
  }

  &.light {
    fill: $white;
  }
}
