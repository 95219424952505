@import '@/assets/colors';
@import '@/assets/styles';

$height: 15px;

.c-progress-bar {
  display: flex;
  position: relative;
  z-index: 0;
  flex-direction: column;

  .c-progress-container {
    margin-top: 3px;
    width: 100%;
    display: flex;
    position: relative;

    .c-bar-outline {
      position: absolute;
      width: 100%;
      height: 4px;
      border-radius: 10px;
      top: 2px;
      z-index: -1;

      &.white {
        background-color: #4c4c57;
      }

      &.dark,
      &.gradient {
        background-color: #a6a6a6;
      }
    }

    .c-inner-bar {
      position: relative;
      height: 8px;
      border-radius: 10px;
      background: $white;

      &.dark {
        background-color: #4c4c57;
      }

      &.gradient {
        background: $blue-gradient;
      }
    }
  }

  label.c-bar-label {
    font-size: 10px;
    white-space: nowrap;
  }

  &.label-on-right {
    flex-direction: row-reverse;

    label.c-bar-label {
      margin-left: 5px;
    }
  }
}
