@import '@/assets/colors';
@import '@/assets/styles';

.c-range-slider {
  .c-range-slider-input {
    width: 100%;
    -webkit-appearance: none;
    height: 7px;
    background: #777777;
    border-radius: 5px;
    background-image: linear-gradient($light-blue, $light-blue);
    background-repeat: no-repeat;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border-radius: 10px;
      height: 20px;
      width: 8px;
      border: 1px solid $deck-cover-blue;
      background: $grey;

      cursor: pointer;
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    &:disabled {
      background-image: linear-gradient($flashcard-white, $flashcard-white);
      background: $flashcard-white;

      &::-webkit-slider-thumb {
        background: $flashcard-white;
        border: 1px solid $light-blue;
      }
    }
  }
}
