@import '@/assets/styles';
@import '@/assets/colors';

.pg-profile-page {
  padding-bottom: 64px;

  .profile-page-header {
    padding-bottom: 12px;

    @media screen and (max-width: $small-screen) {
      padding: 4px 12px 20px 12px; // offset to account for content padding shrinking
    }
  }

  .decks-divider {
    padding: 32px 0px;

    .decks-container {
      @include deck-cover-wrapping-grid;
      padding-top: 8px;

      // we may want to limit the height since we don't have pagination
      // max-height: 50vh;
      // overflow: hidden;
    }
  }
}
