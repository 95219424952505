@import '@/assets/colors';
@import '@/assets/styles';

// screen height without header
$height-without-header: calc(100vh - #{$header-height});

// screen height without header or content padding
$content-height: calc(100vh - #{$header-height + $content-padding});

// only modify the <Header /> that has this class (which should only be this layout)
.sidebar-layout-header.signed-in {
  .c-header-content {
    .c-header-title {
      @media screen and (max-width: $large-screen) {
        flex: 1;
      }
    }

    .c-header-title {
      @media screen and (max-width: $large-screen) {
        flex: 1;
        padding: unset;
      }
    }

    .c-account-buttons {
      @media screen and (max-width: $large-screen) {
        flex: 1;
      }
    }
  }
}

.sidebar-layout-sidebar {
  @media screen and (max-width: $giant-screen) {
    padding-left: unset;
    width: $sidebar-width / 1.15;
  }

  @media screen and (max-width: $large-screen) {
    display: none;
  }
}

.sidebar-layout-page-wrap {
  height: $height-without-header;
  padding-top: $header-height;

  &.signed-in {
    margin-left: $sidebar-width;

    @media screen and (max-width: $giant-screen) {
      margin-left: ($sidebar-width / 1.15);
    }

    @media screen and (max-width: $large-screen) {
      margin-left: 0;
    }
  }

  > .sidebar-layout-content {
    // create a stacking context so all internal z-indexes apply only between sibling elements
    isolation: isolate;

    height: $content-height;
    padding: 20px $content-padding;
    max-width: 1700px;
    margin: auto;

    @media screen and (max-width: $large-screen) {
      height: calc($content-height + 16px); // to offset flashcard-decks margin shrinking
      padding: 16px ($content-padding / 2);
    }

    @media screen and (max-width: $small-screen) {
      padding: 12px ($content-padding / 4);
    }
  }
}
