@import '@/assets/styles';

.study-card {
  aspect-ratio: 325 / 200;
  width: 450px;
  height: auto;

  margin: 10px;
  font-size: 40px;

  @media screen and (max-width: $small-screen) {
    width: 325px;
    font-size: 32px;
  }

  .study-card-front div.c-front-inner {
    height: 100%;
    width: 100%;
  }

  .c-study-flashcard-content {
    @include grid-center;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;

    .c-study-flashcard-side-label {
      position: absolute;
      display: inline-block;
      font-size: 20px;
      top: 10px;
      left: 10px;
    }

    .c-study-flashcard-text {
      @include webkit-scrollbar;
      @include grid-center;
      overflow-y: auto;
      width: 100%;
      height: 100%;
    }
  }

  .dark {
    background-color: $deck-cover-blue;
    color: $white;
  }

  .light {
    background-color: $flashcard-white;
    color: $blue;
  }
}
