@use 'sass:map';
@import '@/components/bubble-tag-list/bubble-tag-colors';
@import '@/assets/colors';
@import '@/assets/styles';

.c-bubble-tags {
  color: $white;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 20px;

  .c-bubble-tag {
    padding: 5px 10px;
    border-radius: 15px;
  }

  .c-bubble-tag-purple {
    background-color: map.get($bubble-colors, 'purple');
  }

  .c-bubble-tag-green {
    background-color: map.get($bubble-colors, 'green');
  }

  .c-bubble-tag-blue {
    background-color: map.get($bubble-colors, 'blue');
  }
}
