@import '@/assets/styles';
@import '@/assets/colors';

$content-top-bottom-margin: 10px;
$card-height: 175px;

.read-only-flashcard {
  @include flex-center;
  width: 100%;
  gap: 20px;
  margin: 10px 0px;

  @media screen and (max-width: $medium-screen) {
    display: block;
    margin: 25px 0px;
  }

  .rof-card-face {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    border: 1px solid $light-blue;
    color: $light-blue;
    border-radius: 14px;
    height: $card-height;
    text-align: center;
    overflow-y: auto;

    @media screen and (max-width: $medium-screen) {
      &:first-child {
        margin-bottom: 10px;
      }
    }

    .rof-button-strip {
      position: sticky;
      top: 0;
      width: 35px;

      .rof-speaker-icon {
        height: 30px;
        margin: 5px;
      }
    }
    .rof-content {
      @include flex-center;
      box-sizing: border-box;
      flex: 1;
      font-size: 25px;
      height: fit-content;
      min-height: $card-height - $content-top-bottom-margin * 2;
      margin: $content-top-bottom-margin 0px;
    }
  }

  &.green {
    .rof-card-face {
      border-color: $muted-green;
      color: $muted-green;
    }
  }

  &.red {
    .rof-card-face {
      border-color: $muted-red;
      color: $muted-red;
    }
  }
}
