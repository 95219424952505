@use '@/pages/view-deck-pages/shared-view-deck-page' as view-deck-page;
@import '@/assets/colors';
@import '@/assets/styles';

.view-personal-deck-page {
  width: 100%;

  .view-personal-deck-header {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: auto min-content;
    grid-template-rows: min-content auto;
    column-gap: 16px;
    overflow-wrap: anywhere;

    .action-button-group {
      display: flex;
      gap: 12px;
    }

    // <= screen size
    @media screen and (max-width: $sm-md-screen) {
      .action-button-group {
        flex-direction: column;
        grid-row: 1 / 3;
        grid-column: 2 / 3;
      }
    }

    // > screen size
    @media screen and (min-width: calc($sm-md-screen + 1px)) {
      align-items: center;

      .view-personal-deck-description {
        grid-column: 1 / -1;
      }

      .action-button-group {
        flex-direction: row;
        grid-row: 1 / 2;
        grid-column: 2 / 3;
      }
    }
  }

  .view-personal-deck-progress-bar {
    max-width: 400px;
    color: white;
    font-size: 20px;
    margin: 20px 0px;
  }

  .view-personal-deck-divider {
    @include view-deck-page.deck-divider;
  }

  .view-personal-deck-description {
    @include view-deck-page.deck-description;
  }
}
