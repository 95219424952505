@import '@/assets/colors';
@import '@/assets/styles';

svg.trash-icon {
  @include standard-transition;

  &.inactive {
    fill: $light-blue;
    path:last-child {
      stroke: $light-blue;
    }
  }
  &.active {
    fill: $flashcard-white;
    path:last-child {
      stroke: $flashcard-white;
    }
  }
  &.dark {
    fill: $blue;
    path:last-child {
      stroke: $blue;
    }
  }
}
