@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;700&display=swap');
@import '@/assets/colors';
@import '@/assets/styles';

$bg-opacity: 0; // opacity to set background if modal is open

.c-account-popup-overlay {
  z-index: $z-popup-modal;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, $bg-opacity);
  transition: visibility 150ms ease-out, opacity 150ms ease-out;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.c-account-popup-content {
  z-index: $z-popup-modal;
  position: absolute;
  right: $header-gap;
  top: 1px;
  background: $white;
  border-radius: $standard-radius * 0.75;

  &.fixed {
    position: fixed;
  }

  @media screen and (max-width: $small-screen) {
    right: 0;
  }
}
