@import '@/assets/colors';
@import '@/assets/styles';

.deck-cover {
  margin: 10px;

  .cover-front {
    @include grid-center;
    padding: 16px;

    background-color: $deck-cover-blue;
    color: $white;
    font-size: 30px;

    div.c-front-inner {
      @include multiline-ellipsis-overflow(4);
    }
  }

  .cover-back {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;

    label {
      @include multiline-ellipsis-overflow(2);
      font-size: 20px;
    }

    .deck-cover-card-count {
      font-size: 12px;
    }

    // description
    p {
      @include webkit-scrollbar;
      width: 100%;
      overflow-wrap: anywhere;
      overflow-y: auto; // only show vertical scroll when needed
      font-size: 12px;
      flex: 1;
    }

    .button-strip {
      display: flex;
      width: 100%;

      button {
        margin-left: 15px;
        border-color: transparent;
      }
    }
  }
}

.deck-cover-progress-bar {
  width: 135px;
}
