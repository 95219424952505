@import '@/assets/colors';
@import '@/assets/styles';

.pg-flashcard-decks {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 0px;

  @media screen and (max-width: $medium-screen) {
    margin: 8px 0px;
  }

  .decks-page-header {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    @media screen and (max-width: $small-screen) {
      padding: 0px 12px; // to offset page margin shrinking
    }

    > label {
      color: $white;
      font-size: 30px;
    }

    .drop-down {
      button,
      label {
        font-size: 20px;
      }
    }
  }

  .deck-tile-container {
    @include webkit-scrollbar;
    @include deck-cover-wrapping-grid;
    position: relative;

    // ideally should have no more than 9-12 decks/pg to avoid overflow
    overflow-x: hidden;
    overflow-y: auto;

    .deck-cover:first-child .cover-front {
      @include standard-transition;
      background-color: $light-blue;

      &:hover {
        color: $blue;
      }
    }

    .decks-loading-icon-container {
      position: absolute;
    }
  }

  .footer-page-number {
    padding: 16px 0px;
    margin-top: auto;
    text-align: center;

    @media screen and (max-width: $medium-screen) {
      padding: unset;
      padding-bottom: 8px;
      margin-right: 12px;
    }

    > label {
      color: $light-blue;
      text-align: center;
    }
  }
}
