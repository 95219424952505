@import '@/assets/colors';
@import '@/assets/styles';

$color: $light-blue;

// loading icon taken from https://loading.io/css/
.c-loading-icon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  &.medium {
    width: 80px;
    height: 80px;

    div {
      width: 64px;
      height: 64px;
      margin: 8px;
      border-width: 8px;
    }
  }

  &.large {
    width: 160px;
    height: 160px;
    div {
      width: 124px;
      height: 124px;
      margin: 16px;
      border-width: 16px;
    }
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 2px;
    border: 2.5px solid $color;
    border-radius: 50%;
    animation: c-loading-icon 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes c-loading-icon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
