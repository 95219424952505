@import '@/assets/colors';
@import '@/assets/styles';

.study-lesson-page {
  padding-top: 16px;

  .study-lesson-page-header {
    @media screen and (max-width: $small-screen) {
      text-align: center;
    }
  }

  .study-lesson-page-content {
    @include flex-center;
    flex-direction: column;
    margin: auto;
    padding-top: 16px;

    .study-flashcard-header-container {
      display: flex;
      align-items: flex-end;
      height: 26px;
      margin-left: 10px;

      .pulsing-microphone {
        @include flex-center;
        @include white-infinite-pulse;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        padding: 5px;
        background-color: $white;
      }

      .play-intro-button {
        @include hover-pointer;
        @include standard-transition;
        color: white;
        display: inline-flex;
        justify-content: left;
        align-items: center;
        background-color: $electric-blue;
        border-radius: 25px;
        padding-right: 15px;
        gap: 15px;

        &:hover {
          background-color: $electric-blue-hover;
        }

        .play-intro-icon-container {
          @include flex-center;
          $container-size: 30px;
          width: $container-size;
          height: $container-size;
          border: 2px solid white;
          border-radius: 50%;

          .play-intro-play-icon {
            padding-left: 3px;
            height: 20px;
          }
        }
      }
    }

    .correct-card-face {
      background-color: $muted-green;
    }

    .incorrect-card-face {
      background-color: $muted-red;
    }

    .study-page-progress-bar {
      padding: 40px 8px 20px 8px;
    }
  }
}
