@import '@/assets/colors';
@import '@/assets/styles';

.c-volume-control {
  @include flex-center;

  .c-volume-control-speaker-icon {
    height: 32px;
    width: 32px;
    margin-right: 5px;

    position: relative;
    isolation: isolate;

    // might just make sense to add a muted speaker svg hahaha
    &.muted::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $light-blue;
      z-index: 1;
      transform: translateY(16px) translateX(2px) rotate(35deg);
    }
  }

  .c-volume-control-slider {
    width: 75px;
  }
}
