@import '@/assets/styles';
@import '@/assets/colors';

.not-found-page {
  @include viewport-vertical-center;
  @include flex-center;
  flex-direction: column;
  font-size: 30px;
  color: white;
  padding-bottom: $header-height;

  .code {
    font-size: 64px;
  }

  .message {
    font-size: 24px;
    text-align: center;
  }

  .wave-image {
    width: 100%;
    max-width: min(90vw, $small-screen);
    height: auto;
    padding: 32px;
    user-select: none;
  }
}
