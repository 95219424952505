@import '@/assets/styles';
@import '@/assets/colors';

.search-page-category-search-bar {
  margin-top: 20px;
}

.search-page-loading-icon {
  margin-top: 100px;
}

.search-page-user-tiles {
  @include wrapping-grid(250px, 32px, 20px);
}

.search-page-public-deck-tiles {
  @include deck-tile-wrapping-grid;
}

.search-page-deck-covers {
  @include deck-cover-wrapping-grid;
}

.search-page-no-results-message {
  margin: 100px;
  @include flex-center;
  color: $grey;
  font-size: 40px;
}
