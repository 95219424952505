@import '@/assets/colors';
@import '@/assets/styles';

.study-results-page {
  @include flex-center;
  flex-direction: column;
  color: $white;
  padding-bottom: 30px;

  hr {
    @include light-hr;
  }

  .study-results-page-header {
    @include flex-center;
    gap: 64px;
    margin: 20px;
    width: 100%;

    > * {
      @include flex-center;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;

      @media screen and (max-width: $giant-screen) {
        flex-wrap: wrap;
      }
    }

    h1 {
      text-align: center;
      flex: 3;

      @media screen and (max-width: $giant-screen) {
        flex: 8;
      }
    }

    .study-results-page-nav-buttons {
      justify-content: flex-end;
      gap: 20px;

      .finish-lesson-content-container {
        @include flex-center;
        width: 100px;
        height: 20px;

        .finish-lesson-loading {
          @include flex-center;
          label {
            padding-left: 8px;
          }
        }
      }

      @media screen and (min-width: $giant-screen) {
        align-self: flex-start;
      }
    }

    @media screen and (max-width: $large-screen) {
      flex-direction: column;
      gap: 8px;

      .study-results-page-nav-buttons {
        justify-content: center;
      }
    }
  }

  .lesson-stat {
    @include flex-center;
    font-size: 25px;
    margin: 20px;
    width: 100%;
    gap: 40px;

    .lesson-stat-label,
    .lesson-stat-result {
      flex-basis: 0;
      flex-grow: 1;
    }

    .lesson-stat-label {
      color: $light-blue;
      text-align: right;
    }

    .lesson-stat-result {
      @include flex-center;
      justify-content: flex-start;

      .lesson-stat-icon {
        margin-left: 10px;
        width: 45px;
        height: 45px;
      }
    }
  }

  .studied-cards-divider {
    width: 100%;
    margin: 20px 0px;
  }
}
